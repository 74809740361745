import styled from "@emotion/styled";
import mq from "@styles/mq";
import { Field } from "formik";
import React from "react";
import { Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";

export const ModalWrapper = styled.div`

  ${mq({
    paddingLeft: ['0px','0px','15px'],
    paddingRight: ['0px','0px','15px']
  })}

  padding-top: 32px;
  padding-bottom: 12px;
  img {
    width: 204px;
  }

  .text{
    font-weight: 100 !important;
    font-family: sans-serif;
  }


  .title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    padding: 0px 60px 0px 60px;
  }

  .summary {
    font-size: 14px;
    color: #707070;
    text-align: center;
    padding: 10px 40px 20px 40px;
    font-weight: 100;
  }

  .tnc {
    font-size: 12px;
    color: #707070;
    text-align: center;
    padding: 10px 40px 20px 40px;
    font-weight: 100;

    a:link {
      color: #707070;
    }
    a:visited {
      color: #707070;
    }

    a:hover {
      color: #707070;
    }
  }
  input {
    border: 1px solid #00000021 !important;
    border-radius: 3px !important;
  }

  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor:pointer;
  }
`

export const VTWrapper = styled.div`{
  svg {
    margin-right: 15px;
    margin-bottom: 5px;
  }

  text-align: left;

  svg:hover {
    cursor:pointer;
    backgrund-color: #3DAE2B;
    filter: brightness(0) saturate(100%) invert(56%) sepia(56%) saturate(613%) hue-rotate(65deg) brightness(89%) contrast(92%);
  }

  .vt-active {
    filter: brightness(0) saturate(100%) invert(56%) sepia(56%) saturate(613%) hue-rotate(65deg) brightness(89%) contrast(92%);
  }
}`