import styled from "@emotion/styled";
import mq from "@styles/mq";
import { Field } from "formik";
import React from "react";
import { Grid, GridColumn, GridRow, Segment } from "semantic-ui-react";

export const ModalWrapper = styled.div`
    ${mq({
      paddingLeft: ['0px','0px','15px'],
      paddingRight: ['0px','0px','15px']
    })}

  padding: 20xp;
  padding-top: 30px;
  img {
    width: 204px;
  }

  .title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
  }

  .summary {
    font-size: 16px;
    color: #707070;
    text-align: center;
    padding: 10px 40px 20px 40px;
    font-weight: 100;
  }

  .center {
    text-align: center;
  }

  .close{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor:pointer;
  }

  .email {
    font-size: 19px;
    font-weight: 100;
    text-align: center;
    margin-top: 10px;
    display: inline;
    vertical-align: middle;

    span {
      font-size: 16px;
      vertical-align: top;
      margin-left: 5px;
    }
  }

  .tnc {
    font-size: 12px;
    color: #707070;
    text-align: center;
    padding: 10px 40px 20px 40px;
    font-weight: 100;
  }

  input {
    border: 1px solid #00000021 !important;
    border-radius: 3px !important;
  }
`