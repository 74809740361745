
import React, { Fragment } from 'react'
import {graphql, useStaticQuery} from 'gatsby'

import {mapToKiwiVIP} from './mapper'
import SignupBanner from './SignupBanner'
import SignupBannerLarge from './SignupBannerLarge'
import { KiwiVipWrapper } from './SingupBanner.styles'

const SignupBannerWrapper = (props: {version: string, anchored?: boolean}) =>{
    const {version, anchored} = props;
    const data = useStaticQuery(graphql`
    query{
        site {
            siteMetadata {
            apiUrl
            emailValidationToken
            }
        }
        kontentItemKiwivipSignup{
            elements{
                title{
                    value
                }
                image{
                  value{
                    url
                    description
                  }
                }
                button__text{
                    value
                }
                button__button_color_theme{
                    value{
                        name
                        codename
                    }
                }
                button__button_type{
                    value{
                        name
                        codename
                    }
                }
                button__url_slug{
                    value
                }
                car_make {
                    value {
                      name
                    }
                }
                motorbike_make {
                    value {
                      name
                    }
                }
                trailer_make {
                    value {
                      name
                    }
                }
            }
        }
    }
    `)

    const kiwiVipData = mapToKiwiVIP(data.kontentItemKiwivipSignup.elements);
    const apiUrl = data.site.siteMetadata.apiUrl;
    const emailValidationToken = data.site.siteMetadata.emailValidationToken;
    return (
        <Fragment>
            {
                <KiwiVipWrapper id="kiwivip-wrapper" ancorhored={anchored}>
                    { version === 'standard' ? <SignupBanner emailValidationToken={emailValidationToken} apiUrl={apiUrl} {...kiwiVipData} /> : <SignupBannerLarge emailValidationToken={emailValidationToken} apiUrl={apiUrl} {...kiwiVipData} />}
                </KiwiVipWrapper>
            }
        </Fragment>
    )
}

export default SignupBannerWrapper;